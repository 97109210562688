import { FC } from 'react';
import { Theme } from './types';
import { StyledSpinner } from './styles';

type Position = Partial<{
  x: number
  y: number
}>

interface Props {
  size: number
  theme?: Theme
  color?: string
  position?: Position
}

export const Spinner: FC<Props> = ({
  size = 48,
  theme = 'light',
  color,
  position,
}) => <StyledSpinner $size={size} $theme={theme} $color={color} $position={position} />;
