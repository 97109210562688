import styled, { css } from 'styled-components';
import type { Align } from './index';

export const Layout = styled.div<{ $align: Align }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  height: 100svh;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 200;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 16px;
  background: rgba(0, 0, 0, 0.5);
  
  ${({ $align }) => $align === 'right' && css`
    align-items: flex-end;
  `}
`;

export const Container = styled.section<{ $isCollapsed: boolean }>`
  width: ${({ $isCollapsed }) => ($isCollapsed ? '400px' : '800px')};
  transition: width 0.5s ease-in-out;
  box-sizing: border-box;
  background: white;
  border-radius: 16px;
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  overflow: hidden;
`;

const getPadding = (withPadding = true) => (withPadding ? '0 16px' : '0');

export const Body = styled.div<{ $isScrollable: boolean, $withPadding?: boolean }>`
  flex: 1;
  position: relative;
  
  ${({ $isScrollable, $withPadding }) => ($isScrollable ? css`
    overflow-y: auto;
    overflow-x: hidden;
  ` : css`
    padding: ${getPadding($withPadding)};
  `)}
`;

export const Content = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
`;
