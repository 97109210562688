import {
  useFloating,
  useClick,
  useInteractions,
  FloatingFocusManager,
  useDismiss,
  FloatingPortal,
  autoUpdate,
  hide,
  offset,
} from '@floating-ui/react';
import { CSSProperties, FC, ReactNode, useMemo, useState } from 'react';
import { Link as LinkIcon } from '@gravity-ui/icons';
import { StyledPopup, StyledPopupLayout } from './styles';
import { Button } from '../Button';

type Props = {
  text?: string
  children: ReactNode
}

export const BlockCodePopup: FC<Props> = ({ children, text }) => {
  const [isOpen, setIsOpen] = useState(false);

  const { refs, floatingStyles, context, middlewareData } = useFloating({
    placement: 'top',
    open: isOpen,
    onOpenChange: setIsOpen,
    whileElementsMounted: autoUpdate,
    middleware: [
      hide({
        strategy: 'referenceHidden',
        padding: 8,
      }),
      offset(8),
    ],
  });

  // TODO: maybe we could do better than this
  const styles = useMemo(() => ({
    ...floatingStyles,
    visibility: middlewareData?.hide?.referenceHidden ? 'hidden' : 'visible',
  }) as CSSProperties, [floatingStyles, middlewareData?.hide?.referenceHidden]);

  const click = useClick(context);
  const dismiss = useDismiss(context);

  const { getReferenceProps, getFloatingProps } = useInteractions([
    click,
    dismiss,
  ]);

  return (
    <StyledPopupLayout ref={refs.setReference}>
      <Button
        view="ghost"
        size="small"
        iconLeft={<LinkIcon />}
        {...getReferenceProps({ onClick: (e) => e.stopPropagation() })}
      >
        {text}
      </Button>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} modal={false}>
            <StyledPopup
              onClick={(e) => e.stopPropagation()}
              ref={refs.setFloating}
              style={styles}
              {...getFloatingProps()}
            >
              {children}
            </StyledPopup>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </StyledPopupLayout>
  );
};
