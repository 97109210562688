import styled from 'styled-components';
import { GlobalColors } from '../GlobalStyle';

export const StyledPopup = styled.div`
  display: flex;
  width: 260px;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  gap: 8px;
  border-radius: 12px;
  background: ${GlobalColors.bg.main.secondary};
  box-shadow: 0 12px 24px 0 rgba(22, 34, 51, 0.06), 0 2px 8px 0 rgba(22, 34, 51, 0.04);
  border: 1px solid rgba(0, 0, 0, 0.05);
  z-index: 10000;
`;

export const StyledPopupLayout = styled.div`
  align-self: flex-start;
  z-index: 10;
`;

export const StyledLink = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 4px;
  padding: 4px 4px 4px 8px;
  border-radius: 8px;
  border: 1px solid ${GlobalColors.border.secondary};
  align-items: center;
`;

export const StyledLinkText = styled.span`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
