import styled from 'styled-components';
import { GlobalColors } from '../../GlobalStyle';

interface ActiveProps {
  $active?: boolean
}

export const StyleContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 120px;
  margin-bottom: 10px;
  font-family: PT Root UI, Arial, sans-serif;
  outline: none;

  [data-placeholder]:first-of-type::before {
    color: #b3b3b3;
    content: attr(data-placeholder);
    float: left;
    height: 0;
    pointer-events: none;
  };

  .ProseMirror:focus {
    outline: none;
  };

  .ProseMirror {
    color: #252525;
  };

  .ProseMirror > p + p {
    margin-top: 16px;
  }

  .ProseMirror ul, ol {
    margin: 16px 0;
    padding: 0 1rem;
  };

  .ProseMirror img {
    width: fit-content;
  };

  .ProseMirror h1 {
    font-size: 2rem;
    margin: 16px 0 8px 0;
  };

  .ProseMirror h2 {
    font-size: 1.714rem;
    margin: 16px 0 8px 0;
  };

  .ProseMirror h3 {
    font-size: 1.143rem;
    margin: 16px 0 8px 0;
  };
`;

export const Header = styled.div`
  position: sticky;
  display: flex;
  flex-direction: column;
  justify-content: end;
  z-index: 100;
  top: 0;
  left: 0;
  height: 60px;
  width: 100%;
  background: #FFFFFF;
  padding-bottom: 8px;
`;

export const ControlPanel = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: baseline;
  gap: 4px;
  background: rgba(13, 45, 94, 0.04);
  width: max-content;
  border-radius: 6px;
  padding: 4px;

`;

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  font-family: PT Root UI, Arial, sans-serif;

  p {
    margin: 0;
  };
`;

export const StyleButton = styled.button<ActiveProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  border: 0;
  border-radius: 4px;
  background: ${({ $active }) => ($active ? '#cdddf7' : 'transparent')};

  svg {
    color: ${GlobalColors.icon.ghost};
  }
`;

export const StyleIcon = styled.div`
  width: 16px;
  height: 16px;
`;

export const StyleColor = styled.div`
  display: flex;
  flex-wrap: nowrap;
  border: 1px solid gray;
  border-radius: 4px;
  padding: 4px;
  gap: 4px;
`;

export const SimpleEditorStyle = styled.div`
  position: relative;
`;

export const CounterStyle = styled.div`
  position: absolute;
  bottom: 8px;
  right: 16px;
  color: #b3b3b3;
`;

export const ReachCounterStyle = styled.div`
  position: absolute;
  bottom: 4px;
  right: 8px;
  color: #b3b3b3;
`;
