type Props = {
  color?: string
  size?: number
}

/* eslint-disable max-len */
export const PlanetEarthIcon = ({ color, size = 16 }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.208 12.346C8.723 13.346 8.255 13.5 8 13.5C7.745 13.5 7.277 13.346 6.792 12.346C6.42 11.578 6.145 10.488 6.043 9.159C7.34476 9.2822 8.65524 9.2822 9.957 9.159C9.855 10.488 9.58 11.578 9.207 12.346H9.208ZM9.996 7.647C9.358 7.714 8.69 7.75 8 7.75C7.31 7.75 6.642 7.714 6.004 7.647C6.041 5.951 6.347 4.572 6.792 3.654C7.277 2.654 7.745 2.5 8 2.5C8.255 2.5 8.723 2.654 9.208 3.654C9.653 4.572 9.958 5.951 9.996 7.647ZM11.474 8.953C11.389 10.469 11.099 11.801 10.638 12.827C11.4355 12.3911 12.1126 11.7643 12.6087 11.0028C13.1048 10.2413 13.4045 9.36866 13.481 8.463C12.86 8.662 12.186 8.827 11.474 8.953ZM13.392 6.91C12.82 7.114 12.182 7.289 11.491 7.424C11.435 5.753 11.137 4.284 10.638 3.173C11.3414 3.5579 11.9522 4.09173 12.4279 4.7372C12.9036 5.38266 13.2326 6.12419 13.392 6.91ZM4.509 7.424C4.565 5.753 4.863 4.284 5.362 3.173C4.65862 3.5579 4.04776 4.09173 3.57208 4.7372C3.0964 5.38266 2.76738 6.12419 2.608 6.91C3.18 7.114 3.818 7.289 4.509 7.424ZM2.52 8.463C2.59649 9.36866 2.89618 10.2413 3.3923 11.0028C3.88841 11.7643 4.56549 12.3911 5.363 12.827C4.903 11.801 4.613 10.469 4.527 8.953C3.84787 8.83438 3.17739 8.67069 2.52 8.463ZM15 8C15 6.14348 14.2625 4.36301 12.9497 3.05025C11.637 1.7375 9.85652 1 8 1C6.14348 1 4.36301 1.7375 3.05025 3.05025C1.7375 4.36301 1 6.14348 1 8C1 9.85652 1.7375 11.637 3.05025 12.9497C4.36301 14.2625 6.14348 15 8 15C9.85652 15 11.637 14.2625 12.9497 12.9497C14.2625 11.637 15 9.85652 15 8Z"
      fill={color ?? '#222934'}
    />
  </svg>
);
