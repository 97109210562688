import { FC, useState, MouseEvent } from 'react';
import { Copy } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { Button } from '../Button';
import { StyledLink, StyledLinkText } from './styles';

type Props = {
  link: string
}

export const LinkBlock: FC<Props> = ({ link }) => {
  const { t } = useTranslation('ui');
  const [copied, setCopied] = useState(false);

  const copyToClipboard = async (e: MouseEvent) => {
    e.stopPropagation();
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
    await navigator.clipboard.writeText(link);
  };

  return (
    <StyledLink>
      <StyledLinkText>
        {copied ? t('codePopup.copied') : link}
      </StyledLinkText>
      <Button size="small" view="ghost" onClick={copyToClipboard}>
        <Copy width={14} height={14} />
      </Button>
    </StyledLink>
  );
};
