import {
  FC,
  ImgHTMLAttributes,
} from 'react';
import { styled } from 'styled-components';
import Stub from './stub.svg';
import { useFastlane } from './hooks';

interface Props extends ImgHTMLAttributes<HTMLImageElement> {
  src: string
  alt?: string
  fallback?: string
}

const Image = styled.img<{ $fallback: string }>`
  object-fit: contain;
  height: 100%;
  width: auto;
  margin: 0 auto;
  font-size: 0;
  
  &::before {
    content: '';
    display: block;
    position: absolute;
    background: url("${({ $fallback }) => $fallback}") no-repeat center center;
    background-size: contain;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
`;

export const LazyImage: FC<Props> = ({ src, alt = '', fallback, ...rest }) => {
  const { url } = useFastlane(src);

  return (
    <Image
      alt={alt}
      loading="lazy"
      $fallback={fallback ?? Stub.src}
      src={url}
      {...rest}
    />
  );
};
