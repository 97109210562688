import { createNanoEvents } from 'nanoevents';

interface Events {
  load: ({ src, newSrc }: { src: string, newSrc?: string }) => void,
}

type FastlaneEventName = 'load'

type FastlaneEventPayload = {
  id: string
  duplicateId?: string
}

export const emitter = createNanoEvents<Events>();

export const emitFastlane = (eventName: FastlaneEventName, payload: FastlaneEventPayload) => {
  if (payload.id && eventName === 'load') {
    const sources = {} as { src: string, newSrc?: string };
    const folder = payload.id.substring(0, 2);
    sources.src = `/media/${folder}/${payload.id}`;

    if (payload.duplicateId) {
      const duplicatedFolder = payload.duplicateId.substring(0, 2);
      sources.newSrc = `/media/${duplicatedFolder}/${payload.duplicateId}`;
    }

    emitter.emit(eventName, sources);
  }
};
