import { ChangeEvent, FC, forwardRef, ReactNode } from 'react';
import { Switch } from '@mui/base/Switch';
import type { SwitchOwnerState } from '@mui/base/Switch/Switch.types';
import { Root } from './styles';

type Size = 'small' | 'medium' | 'large'
export interface Props {
  checked: boolean
  onChange: () => void
  label?: string
  size?: Size
  disabled?: boolean
}

interface SwitchProps extends Record<string, unknown> {
  children: ReactNode
  ownerState: SwitchOwnerState & { size: Size }
}

const RootSwitch = forwardRef<HTMLButtonElement, SwitchProps>((props, ref) => {
  const { ownerState, ...rest } = props;

  return (
    <Root $size={ownerState.size} ref={ref} {...rest} />
  );
});

export const SwitchBase: FC<Props> = ({ checked, onChange, label, size, disabled = false }) => {
  const props = { slotProps: { input: { 'aria-label': label } }, $size: size };

  return (
    <Switch
      slots={{
        root: RootSwitch,
      }}
      disabled={disabled}
      checked={checked}
      onChange={onChange}
      onClick={(e) => e.stopPropagation()}
      {...props}
    />
  );
};
