import styled from 'styled-components';
import { Size } from './types';

export const StyledLayout = styled.div<{ $centred: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${({ $centred }) => ($centred ? 'center' : 'flex-start')};
  width: 100%;
  z-index: 1000;
  box-sizing: border-box;
  padding: 24px;
  background: rgba(0, 0, 0, 0.5);
  overflow-y: auto;
`;

export const StyledContainer = styled.section<{ $size: Size, $width: string }>`
  padding: ${({ $size }) => ($size === 'newDefault'
    ? '36px 48px;'
    : '16px 24px 24px;')};
  border-radius: 16px;
  box-sizing: border-box;
  background-color: white;
  display: flex;
  flex-direction: column;
  ${({ $size }) => ($size !== 'newDefault' && 'gap: 24px;')};
  max-width: ${({ $size }) => {
    switch ($size) {
      case 'small':
        return '400px';

      case 'newDefault':
        return '440px';

      case 'large':
        return '912px';

      case 'xl':
        return '960px';

      case 'medium':
      default:
        return '600px';
    }
  }};
  width: ${({ $width }) => ($width || '100%')};
`;

export const StyledHeader = styled.header`
  display: flex; 
  justify-content: flex-end;
  align-items: center;
  column-gap: 16px;
    
    & > button {
        position: absolute;
        top: 10px;
        right: 10px;
    }
`;

export const StyledTitle = styled.h2`
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  flex: 1;
`;

export const StyledBody = styled.div`
  flex: 1;
`;

export const StyledFooter = styled.footer`
  padding-top: 8px;
  display: flex;
  gap: 8px;
`;
