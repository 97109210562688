import styled from 'styled-components';
import { Button } from '../Button';
import { GlobalColors } from '../GlobalStyle';

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-self: center;
  align-self: center;
  align-items: center;
  text-align: center;
  flex: 1;
  max-width: 400px;
  gap: 20px;
`;

export const StyledImage = styled.div<{ $width: string }>`
  width: ${({ $width }) => $width};
`;

export const TextContainer = styled.div<{ $isReverse: boolean }>`
  display: flex;
  flex-direction: ${({ $isReverse }) => ($isReverse ? 'column-reverse' : 'column')};
  gap: ${({ $isReverse }) => ($isReverse ? '0' : '12px')};;
`;

export const StyledButton = styled(Button)`
  all: unset;
  color: ${GlobalColors.text.accent};
  cursor: pointer;

  &:hover {
    background: none;
  }
`;

export const StyledText = styled.span`
  color: ${GlobalColors.text.secondary};
  & + & {
    margin-top: 8px;
  }
`;

export const StyledTitle = styled.h2`
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  color: ${GlobalColors.text.primary};
  padding: 0 80px;
`;
