import { ReactNode, useState } from 'react';
import type { FC } from 'react';
import { Modal } from '../Modal';
import { StyledImage, StyledLayout, TextContainer, StyledText, StyledButton, StyledTitle } from './styles';

interface Props {
  hasWorkspace?: boolean
  image?: ReactNode
  prev?: string | null
  buttonText?: string
  post?: string | null
  modalBody?: ReactNode
  reverse?: boolean
  width?: string
  title?: string | null
}

export const EmptyList: FC<Props> = ({
  hasWorkspace,
  image,
  prev,
  post,
  modalBody,
  buttonText,
  reverse = false,
  width = '240px',
  title,
}) => {
  const [isVisible, setVisible] = useState(false);

  return (
    <StyledLayout>
      <StyledImage $width={width}>{image}</StyledImage>
      <TextContainer $isReverse={reverse}>
        {title && <StyledTitle>{title}</StyledTitle>}
        {prev && <StyledText>{prev}</StyledText>}
        {!hasWorkspace && (
          <div>
            <StyledButton
              view="ghost"
              onClick={() => setVisible(true)}
            >
              {buttonText}
            </StyledButton>
            {post && <StyledText>{post}</StyledText>}
          </div>
        )}
      </TextContainer>

      <Modal
        isVisible={isVisible}
        width="auto"
        onClose={() => setVisible(false)}
        title={buttonText}
      >
        {modalBody}
      </Modal>
    </StyledLayout>
  );
};
