type Props = {
  color?: string
  size?: number
}

/* eslint-disable max-len */
export const PiggyIcon = ({ color, size = 16 }: Props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 16 17" fill="none">
    <g clipPath="url(#clip0_1514_7216)">
      <path d="M6.8998 4.30023C6.2998 2.80023 4.9998 2.30023 4.2998 2.10023C3.9998 2.00023 3.7998 2.20023 3.7998 2.50023L3.8998 5.00023C3.0998 5.50023 2.8998 5.70023 2.5998 6.50023C2.3998 6.80023 2.0998 7.10023 1.6998 7.10023H0.799805V10.9002H1.6998C1.9998 10.9002 2.3998 11.2002 2.4998 11.5002C2.6998 12.1002 3.2998 12.7002 3.8998 12.8002L4.5998 15.1002H11.0998L11.5998 12.8002C13.0998 12.1002 13.6998 10.6002 13.6998 8.80023C13.6998 7.70023 13.2998 6.70023 12.5998 5.90023" stroke={color ?? '#222934'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M14.2002 8.2999C14.7002 8.1999 15.0002 7.8999 15.2002 7.3999" stroke={color ?? '#222934'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M4.69941 8.1C5.14124 8.1 5.49941 7.74183 5.49941 7.3C5.49941 6.85817 5.14124 6.5 4.69941 6.5C4.25759 6.5 3.89941 6.85817 3.89941 7.3C3.89941 7.74183 4.25759 8.1 4.69941 8.1Z" fill={color ?? '#222934'} />
      <path d="M10.0998 6.2001C11.3701 6.2001 12.3998 5.17035 12.3998 3.9001C12.3998 2.62984 11.3701 1.6001 10.0998 1.6001C8.82955 1.6001 7.7998 2.62984 7.7998 3.9001C7.7998 5.17035 8.82955 6.2001 10.0998 6.2001Z" stroke={color ?? '#222934'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M7.7998 15V13.5" stroke={color ?? '#222934'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
      <path d="M11.6002 6.2002H8.2002" stroke={color ?? '#222934'} strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round" />
    </g>
    <defs>
      <clipPath id="clip0_1514_7216">
        <rect width={size} height={size} fill="white" transform="translate(0 0.5)" />
      </clipPath>
    </defs>
  </svg>
);
