import React, { ChangeEvent, FC, useCallback, useState, useMemo, useRef } from 'react';
import {
  ChevronDown, ChevronUp, Plus, TrashBin, Picture, ChevronLeft, ChevronRight,
} from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { GlobalColors } from '../GlobalStyle';
import {
  Wrapper, Content, Title, ButtonUpdateFile, FileInput, NavBlock, Slide, ImageBlock, ImageButton, Image,
  SlideImage, EmptyStyle, DeleteButton, ArrowButton, SlideButtons, SlideCount, SlideArrowButton,
} from './styles';

interface Props {
  id: string
  images?: string[]
  title?: string
  onUpload?: (file: File) => void
  onDelete?: (imageId: string) => Promise<void>;
}

export const Carousel: FC<Props> = ({ id, images, title, onUpload, onDelete }) => {
  const { t } = useTranslation();
  const imageBlockRef = useRef<HTMLDivElement>(null);

  const [currentIndex, setCurrentIndex] = useState(0);

  const slidesCount = useMemo(() => images?.length || 0, [images]);

  const scrollToImage = useCallback((index: number) => {
    const imagesList = Array.from(imageBlockRef.current?.children || []);
    const image = imagesList[index];

    requestAnimationFrame(() => {
      image?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });
  }, []);

  const nextHandler = useCallback(() => {
    const nextImage = currentIndex + 1;
    if (nextImage <= slidesCount) {
      setCurrentIndex(nextImage);
      scrollToImage(nextImage);
    }
  }, [currentIndex, slidesCount, scrollToImage]);

  const prevHandler = useCallback(() => {
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentIndex(prevIndex);
      scrollToImage(prevIndex);
    }
  }, [currentIndex, scrollToImage]);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    const fileObj = e.target.files && e.target.files[0];
    if (fileObj) {
      onUpload && onUpload(fileObj);
      if (images?.length === 0) {
        setCurrentIndex(0);
      }
    }
  };

  const handleImageClick = (index: number) => {
    scrollToImage(index);
    setCurrentIndex(index);
  };

  const deleteHandler = async (url: string) => {
    onDelete && await onDelete(url);
    if (images?.length === currentIndex + 1) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  if (!images?.length) {
    return (
      <Wrapper>
        <Title>{title}</Title>
        <EmptyStyle>
          <Picture width={40} height={40} color={GlobalColors.border.primary} />
          <span>{t('offer.form.emptyImagesText')}</span>
        </EmptyStyle>

        <ButtonUpdateFile htmlFor={id}>
          {t('offer.form.uploadProductImage')} <Plus />
          <FileInput
            type="file"
            name={id}
            id={id}
            accept="image/png, image/jpeg, image/webp"
            onChange={handleFileChange}
          />
        </ButtonUpdateFile>
      </Wrapper>
    );
  }

  return (
    <Wrapper>
      <Title>{title}</Title>

      <Content>
        <NavBlock>
          <ArrowButton
            view="secondary"
            onClick={prevHandler}
            disabled={currentIndex === 0}
          >
            <ChevronUp width={16} height={16} />
          </ArrowButton>

          <ImageBlock ref={imageBlockRef}>
            {images.length > 0 ? images.map((src: string, index) => (
              <ImageButton
                type="button"
                $isActive={currentIndex === index}
                key={src}
                onClick={() => handleImageClick(index)}
              >
                <Image alt="pic" src={src} />
              </ImageButton>
            )) : null}
          </ImageBlock>

          <ArrowButton
            view="secondary"
            onClick={nextHandler}
            disabled={currentIndex === slidesCount - 1}
          >
            <ChevronDown width={16} height={16} />
          </ArrowButton>
        </NavBlock>

        <Slide>
          <SlideButtons>
            <SlideArrowButton
              onClick={prevHandler}
              disabled={currentIndex === 0}
            >
              <ChevronLeft width={16} height={16} />
            </SlideArrowButton>

            <SlideArrowButton
              onClick={nextHandler}
              disabled={currentIndex === slidesCount - 1}
            >
              <ChevronRight width={16} height={16} />
            </SlideArrowButton>
          </SlideButtons>

          <SlideImage alt="pic" src={images[currentIndex]} />

          <SlideCount>{currentIndex + 1} {t('offer.form.from')} {slidesCount}</SlideCount>

          {onDelete && (
            <DeleteButton onClick={() => deleteHandler(images[currentIndex])} view="ghost">
              <TrashBin />
            </DeleteButton>
          )}
        </Slide>

      </Content>

      {onUpload && (
        <ButtonUpdateFile htmlFor={id}>
          {t('offer.form.uploadProductImage')} <Plus />

          <FileInput
            type="file"
            name={id}
            id={id}
            accept="image/png, image/jpeg, image/webp"
            onChange={handleFileChange}
          />
        </ButtonUpdateFile>
      )}
    </Wrapper>
  );
};
