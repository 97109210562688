import { useEffect, useState } from 'react';
import { emitter } from './events';

export const useFastlane = (src: string) => {
  const [url, setUrl] = useState<string | undefined>(src);

  useEffect(() => {
    setUrl(src);
    const unbind = emitter.on('load', ({ src: imageUrl, newSrc }) => {
      const srcUrl = src.split('?');
      if (srcUrl[0] === imageUrl) {
        const date = Date.now();
        let resultUrl = newSrc ?? imageUrl;
        resultUrl += srcUrl[1] ? `?${srcUrl[1]}&update=${date}` : `?update=${date}`;

        setUrl(resultUrl);
      }
    });

    return () => {
      unbind();
    };
  }, [src]);

  return { url };
};
