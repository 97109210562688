import { FC, useMemo } from 'react';
import { ArrowUpRightFromSquare } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { Button } from '../Button';
import { BlockCodePopup } from './BlockCodePopup';
import { LinkBlock } from './LinkBlock';

interface Props {
  url: string
  text?: string
  withHost?: boolean
}

export const CodePopup: FC<Props> = ({ url, text, withHost = true }) => {
  const { t } = useTranslation('ui');

  const link = useMemo(() => {
    if (withHost) {
      const host = window.location.origin;
      return `${host}/${url}`;
    }

    return url;
  }, [url, withHost]);

  const handleClick = (event: { stopPropagation: () => void; }) => {
    window.open(link, '_blank');
    event.stopPropagation();
  };

  return (
    <BlockCodePopup text={text}>
      <LinkBlock link={link} />
      <Button view="ghost" colorTheme="blue" onClick={handleClick}>
        <ArrowUpRightFromSquare width={14} height={14} />
        {t('codePopup.open')}
      </Button>
    </BlockCodePopup>
  );
};
