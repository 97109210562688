import React, { ChangeEvent, FC, useCallback, useState } from 'react';
import { CloudArrowUpIn } from '@gravity-ui/icons';
import { useTranslation } from 'next-i18next';
import { Wrapper, IconStyle, TextStyle, ErrorMassage, FileInput, SubText } from './styles';

// images formats 'jpg/jpeg/png';

export interface ImageUploadProps {
  size?: 120 | 64
  limitSize?: number
  /** * string HTML attribute accept */
  acceptFormats?: string
  uploadFile: (files: File[] | null) => void
}

const getShortName = (name: string) => {
  if (name.length > 19) {
    return `${name.substr(0, 8)}...${name.substr(-8)}`;
  }
  return name;
};

export const ImageUpload: FC<ImageUploadProps> = ({
  size = 120,
  uploadFile,
  acceptFormats,
  limitSize,
}) => {
  const { t: translation } = useTranslation();

  const [error, setError] = useState<string | boolean>(false);
  const [isDrag, setIsDrag] = useState(false);

  const dragStartHandler = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDrag(true);
  };
  const dragLeaveHandler = (e: React.DragEvent) => {
    e.preventDefault();
    setIsDrag(false);
  };

  const checkIsValid = useCallback((file: File) => {
    const formats = acceptFormats ? acceptFormats.split('/') : ['jpg', 'jpeg', 'png'];
    const accepted = formats.some((format) => file.name.toLowerCase().endsWith(format));
    if (!accepted) {
      setError(`${getShortName(file.name)} ${translation('fileUpload.error.notSupported').toString()}`);
      return false;
    }

    if (limitSize && file.size > limitSize) {
      setError(`${getShortName(file.name)} ${translation('fileUpload.error.fileLarge').toString()}`);
      return false;
    }
    setError(false);
    return true;
  }, [acceptFormats, limitSize, translation]);

  const buttonFileUpload = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    if (e.target.files && e.target.files[0] && checkIsValid(e.target.files[0])) {
      uploadFile(Array.from(e.target.files));
    }
  }, [checkIsValid, uploadFile]);

  const dropFileHandler = useCallback((ev: React.DragEvent) => {
    ev.preventDefault();
    setIsDrag(false);

    const fileList: File[] = [];
    if (ev.dataTransfer.items) {
      [...ev.dataTransfer.items].forEach((item, i) => {
        if (item.kind === 'file') {
          const file = item.getAsFile();

          if (file && checkIsValid(file)) {
            fileList.push(file);
          }
        }
      });
    } else {
      [...ev.dataTransfer.files].forEach((file, i) => {
        if (file && checkIsValid(file)) {
          fileList.push(file);
        }
      });
    }
    uploadFile(fileList);
  }, [checkIsValid, uploadFile]);

  return (
    <div>
      <Wrapper
        $isSelected={false}
        $width={`${size}px`}
        onDragStart={(e) => dragStartHandler(e)}
        onDragLeave={(e) => dragLeaveHandler(e)}
        onDragOver={(e) => dragStartHandler(e)}
        onDrop={(e) => dropFileHandler(e)}
      >
        <IconStyle>
          <CloudArrowUpIn width={16} height={16} />
        </IconStyle>

        <SubText $isDisabled={false}>
          <TextStyle>{isDrag ? translation('fileUpload.drop') : translation('fileUpload.drag')}<br />
            {' '}{translation('fileUpload.file')}<br />{translation('fileUpload.or')}
            <span>{translation('fileUpload.upload')}</span>
          </TextStyle>
        </SubText>
        <FileInput
          type="file"
          name="file"
          id="file"
          accept={acceptFormats ?? 'image/png, image/jpeg, image/webp'}
          onChange={buttonFileUpload}
        />
      </Wrapper>

      {error && <ErrorMassage $width={`${size}px`}>{error}</ErrorMassage>}
    </div>
  );
};
