import styled, { css, keyframes } from 'styled-components';
import { Theme } from './types';

const rotate = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

type Position = Partial<{
  x: number
  y: number
}>

export const StyledSpinner = styled.div<{ $size: number, $theme: Theme, $color?: string, $position?: Position }>`
  width: ${({ $size = 48 }) => $size}px;
  height: ${({ $size = 48 }) => $size}px;
  ${({ $color, $theme }) => ($color
    ? `border: 2px solid ${$color};`
    : `border: 2px solid ${$theme === 'light' ? 'rgba(0, 0, 0, 0.15)' : 'rgb(34,41,52)'};
  `)};
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: ${rotate} 1s linear infinite;
  opacity: 0.7;
  margin-top: ${({ $position }) => $position?.y ?? 0}px;
  margin-left: ${({ $position }) => $position?.x ?? 0}px;
`;
